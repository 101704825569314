<template>
  <div class="font-semibold flex flex-row items-center justify-between shadow text-sm transition-all overflow-hidden" :class="displayClasses">
    <div class="flex flex-row flex-nowrap items-center">
      <yb-icon class="mr-2 h-5 w-5" icon="info" />
      <div v-if="errorMessage != ' '" class="select-text">
        {{ errorMessage }}
      </div>
      <slot v-else />
    </div>
    <div v-if="showClose" class="flex flex-row flex-nowrap items-center" @click="$emit('close')">
      <yb-icon class="mr-2 h-3 w-3 cursor-pointer" icon="delete" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showError: {
      type: Boolean,
      required: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String
    },
    isMessage: {
      type: Boolean
    },
    isBanner: {
      type: Boolean
    }
  },
  computed: {
    displayClasses() {
      const result = []
      if (this.showError && this.errorMessage) {
        result.push('p-2')
        if (this.isMessage) {
          result.push('bg-yb-gray-faint dark:bg-yb-gray-light dark:text-yb-black')
        } else if (this.isBanner) {
          result.push('bg-yb-bluebird text-white')
        } else {
          result.push('bg-yb-yellow dark:text-yb-black')
        }
      } else {
        result.push('max-h-0')
      }
      return result
    }
  }
}
</script>
